import { ProjectTypesEnum } from 'src/type/project.type'

export const ProjectResultIntl: Record<ProjectTypesEnum, { [key: string]: string }> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    kpiRatioSummery: `施策対象となる顧客の離反率`,
    globalTargetRatio: `全体離反率`,
    expectedEarn: `施策により得られる収益`,
    actualProfit: `施策により得られる利益`,
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    kpiRatioSummery: `施策対象となる顧客のクロスセル率`,
    globalTargetRatio: `全体クロスセル率`,
    expectedEarn: `施策により得られる収益`,
    actualProfit: `施策により得られる利益`,
  },
  [ProjectTypesEnum.UP_SELLING]: {
    kpiRatioSummery: `施策対象となる顧客のアップセル率`,
    globalTargetRatio: `全体アップセル率`,
    expectedEarn: `施策により得られる収益`,
    actualProfit: `施策により得られる利益`,
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    kpiRatioSummery: `抽出リストにおける融資実行率`,
    globalTargetRatio: `全体における融資実行率`,
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    kpiRatioSummery: `CSI first kpi`,
    globalTargetRatio: `CSI first ratio`,
    expectedEarn: `CSI first earn`,
    actualProfit: `CSI first profit`,
  },
}

export const SegmentNameIntl: Record<string, string> = {
  primaryKey: `第一Key変数`,
  secondaryKey: `第二Key変数`,
  balance: `残高`,
  gender: `性別`,
  industry: `職種`,
  age: `年齢`,
  subscriptionPeriod: `商品加入後の経過日数`,
  limitAmount: `限度額`,
  target: `目的変数`,
  isSalary: `メインバンク利用`,
  isTargetProductCustomer: `クロスセル商品に加入済みの顧客`,
  corporationAge: `業歴`,
  transactionsTotal_viewPoint: `月間合計取引金額`,
  transactionsCount_viewPoint: `月間合計取引回数`,
  transactionsAvg_viewPoint: `一回あたりの平均取引金額`,
  postCode1: `住所(都道府県)`,
  postCode2: `住所(市区郡町村)`,
  postCode3: `住所(町村以下)`,
  numberOfEmployees: `従業員数`,
  isPayment: `直近3か月における利払い有無`,
  isLoan: `直近3か月における融資実行有無`,
  'transactionsTotal_viewPoint-1months': `先月の月間合計取引金額`,
  'transactionsCount_viewPoint-1months': `先月の月間合計取引回数`,
  'transactionsAvg_viewPoint-1months': `先月の一回あたりの平均取引金額`,
  'transactionsTotal_viewPoint-2months': `先々月の月間合計取引金額`,
  'transactionsCount_viewPoint-2months': `先々月の月間合計取引回数`,
  'transactionsAvg_viewPoint-2months': `先々月の一回あたりの平均取引金額`,
  deposit_transactionsTotal_viewPoint: `当月の月間入金取引金額`,
  deposit_transactionsCount_viewPoint: `当月の月間入金取引回数`,
  deposit_transactionsAvg_viewPoint: `当月の1回あたり平均入金取引金額`,
  'deposit_transactionsTotal_viewPoint-1months': `先月の月間入金取引金額`,
  'deposit_transactionsCount_viewPoint-1months': `先月の月間入金取引回数`,
  'deposit_transactionsAvg_viewPoint-1months': `先月の1回あたり平均入金取引金額`,
  'deposit_transactionsTotal_viewPoint-2months': `先々月の月間入金取引金額`,
  'deposit_transactionsCount_viewPoint-2months': `先々月の月間入金取引回数`,
  'deposit_transactionsAvg_viewPoint-2months': `先々月の1回あたり平均入金取引金額`,
  withdrawal_transactionsTotal_viewPoint: `当月の月間出金取引金額`,
  withdrawal_transactionsCount_viewPoint: `当月の月間出金取引回数`,
  withdrawal_transactionsAvg_viewPoint: `当月の1回あたり平均出金取引金額`,
  'withdrawal_transactionsTotal_viewPoint-1months': `先月の月間出金取引金額`,
  'withdrawal_transactionsCount_viewPoint-1months': `先月の月間出金取引回数`,
  'withdrawal_transactionsAvg_viewPoint-1months': `先月の1回あたり平均出金取引金額`,
  'withdrawal_transactionsTotal_viewPoint-2months': `先々月の月間出金取引金額`,
  'withdrawal_transactionsCount_viewPoint-2months': `先々月の月間出金取引回数`,
  'withdrawal_transactionsAvg_viewPoint-2months': `先々月の1回あたり平均出金取引金額`,
}

export const SegmentItemIntl: Record<ProjectTypesEnum, { [key: string]: string }> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    average: `休眠率`,
    size: `人数`,
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    average: `クロスセル率`,
    size: `人数`,
  },
  [ProjectTypesEnum.UP_SELLING]: {
    average: `アップセル率`,
    size: `人数`,
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    average: `融資実行比率`,
    size: `該当数`,
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    average: `休眠率`,
    size: `人数`,
  },
}
