import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { FilterItem } from 'src/component/molecule/filter-item/filter-item'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { SegmentNameIntl } from 'src/intl/data/result.key'
import { datasetSelector } from 'src/redux/dataset/dataset.state'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import iconDropdown from 'src/style/icon/icon-dropdown.svg'
import { DatasetTypesEnum, FieldTypesEnum } from 'src/type/dataset.type'
import { FilterContentType, FilterFieldType, ProjectTypesEnum, TargetType } from 'src/type/project.type'
import styles from './download-target-list.scss'

type PropsType = {
  onClose(): void
}

export const DownloadTargetListModal = withModalWrapper(({ onClose }: PropsType) => {
  const dispatch = useDispatch()

  const [filterVisible, setFilterVisible] = useState(false)
  const [filterList, setFilterList] = useState<(FilterContentType | undefined)[]>([undefined])

  const project = useSelector(projectSelector.selectedProject)
  const projectResultTargetsPrev = useSelector(projectSelector.selectedProjectResultTargetsPrev)
  const projectDatasets = useSelector(datasetSelector.datasetWithDatasetTypes)
  const selectedProjectTargetsColumns = useSelector(projectSelector.selectedProjectTargetsColumns)

  const projectSetting = useSelector(projectSelector.selectedProjectSettingValues)
  const projectgoals = useSelector(projectSelector.selectedProjectGoal)

  useEffect(() => {
    if (project) {
      const filterParams = filterList.filter((filter): filter is FilterContentType => !!filter)
      dispatch(
        projectAction.fetchProjectResultTargetsPrev.request({ id: project.id, filters: filterParams, limit: 20 }),
      )
    }
  }, [filterList])

  const targetNumberOfSamples = useMemo(() => {
    const targetNumberGoal = projectgoals?.find((goal) => goal.name === 'targetNumberOfSamples')
    return (targetNumberGoal && projectSetting[targetNumberGoal.id]) || 0
  }, [projectSetting, projectgoals])

  const targetTopList = useMemo(() => {
    if (projectResultTargetsPrev) {
      const resultTargets = [...projectResultTargetsPrev]
      return resultTargets.sort((a, b) => -a.score + b.score).slice(0, +targetNumberOfSamples)
    }
    return []
  }, [projectResultTargetsPrev, targetNumberOfSamples])

  const targetResultsColumnDefs = useMemo(() => {
    const res: DataTableColumnDefsType[] = []

    if (targetTopList.length > 0) {
      res.push({ ...targetResultsColumnDefObjs.primaryKey, label: selectedProjectTargetsColumns[0].name })

      if (targetTopList[0].secondaryKey) {
        res.push({ ...targetResultsColumnDefObjs.secondaryKey, label: selectedProjectTargetsColumns[1].name })
      }

      res.push({ ...targetResultsColumnDefObjs.score, label: SegmentNameIntl['score'] || `Score` })
      res.push({ ...targetResultsColumnDefObjs.age, label: SegmentNameIntl['age'] || `Age` })
      res.push({ ...targetResultsColumnDefObjs.gender, label: SegmentNameIntl['gender'] || `Gender` })
      res.push({ ...targetResultsColumnDefObjs.balance, label: SegmentNameIntl['balance'] || `Balance` })
      res.push({
        ...targetResultsColumnDefObjs.transactionsAvg_viewPoint,
        label: SegmentNameIntl['transactionsAvg_viewPoint'] || `Trans Avg view point`,
      })
    }
    return res
  }, [targetTopList, selectedProjectTargetsColumns])

  const selectedFields = useMemo(() => {
    return filterList.map((item) => item?.name).filter((i): i is keyof TargetType => !!i)
  }, [filterList])

  const filterColumns = useMemo(() => {
    return Object.entries(targetTopList[0] || {})
      .map(([key, value]) => {
        if (value !== null) {
          const projectDatasetFields = [...(projectDatasets[DatasetTypesEnum.TEST].fields || [])]
          if (project?.type === ProjectTypesEnum.CROSS_SELLING) {
            projectDatasetFields.push({
              id: 0,
              createdAt: '',
              updatedAt: '',
              name: 'isTargetProductCustomer',
              type: FieldTypesEnum.CATEGORY,
              category: { values: ['0', '1'] },
              number: null,
              date: null,
            })
          }

          const field = projectDatasetFields.find(
            (field) => field.name === key && !['primaryKey', 'secondaryKey', 'score'].includes(key),
          )
          return {
            name: key,
            type: field?.type,
            option:
              field?.type === FieldTypesEnum.CATEGORY
                ? field.category?.values
                : field?.type === FieldTypesEnum.NUMBER
                ? field?.number && [field?.number?.min, field?.number?.max]
                : undefined,
          }
        }
      })
      .filter((item): item is FilterFieldType => !!(item && item.name && item.type))
  }, [targetTopList, projectDatasets])

  function handelClickFilterVisible() {
    setFilterVisible(!filterVisible)
  }

  function handleChangeFilterItem(filterContent: FilterContentType | undefined, idx: number) {
    const newFilterList = [...filterList]
    if (filterContent) {
      newFilterList[idx] = filterContent
      if (!filterList[idx]) {
        newFilterList.push(undefined)
      }
    } else {
      newFilterList.splice(idx, 1)
    }
    setFilterList(newFilterList)
  }

  function handleDownloadClick() {
    const filterParams = filterList.filter((filter): filter is FilterContentType => !!filter)
    if (project) {
      dispatch(projectAction.downloadProjectResultTargets.request({ projectId: project.id, filters: filterParams }))
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={classNames(styles.header, styles.flexEnd2End)}>
          <span className={styles.title}>{`ターゲットリスト（プレビュー）`}</span>
          <Button
            sizeType='sm'
            onClick={handelClickFilterVisible}
            buttonColor={filterVisible ? 'black' : undefined}
            outlined={!filterVisible}
          >
            <div className={styles.filterBtnContent}>
              Filters
              <InlineSVG
                className={classNames(styles.dropdownIcon, { [styles.reversed]: filterVisible })}
                src={iconDropdown}
              />
            </div>
          </Button>
        </div>
        {filterVisible && (
          <div className={styles.filters}>
            {filterList.map((filter, idx) => (
              <FilterItem
                key={filter?.name || `new${idx}`}
                filterContent={filter}
                fields={filterColumns}
                onChange={(filter) => handleChangeFilterItem(filter, idx)}
                selectedFields={selectedFields}
              />
            ))}
          </div>
        )}
        <div className={styles.listGrid}>
          <DataTable
            dataColumnDefs={targetResultsColumnDefs}
            dataList={projectResultTargetsPrev}
            headerHeight={35}
            tableHeight={350}
            bodyBorder
            columnSeperator
            rowSeperator
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleDownloadClick}>
          {`ダウンロード`}
        </Button>
      </div>
    </div>
  )
}, `ターゲットリストのダウンロード`)

const targetResultsColumnDefObjs: Record<string, DataTableColumnDefsType> = {
  primaryKey: {
    dataKey: 'primaryKey',
    label: 'Key',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  secondaryKey: {
    dataKey: 'secondaryKey',
    label: '2nd Key',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  score: {
    dataKey: 'score',
    label: 'Score',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
    cellDataFormat: (data) => {
      return data ? (Math.round((+data + Number.EPSILON) * 10000) / 100).toFixed(2) : 0
    },
  },
  age: {
    dataKey: 'age',
    label: 'Age',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  gender: {
    dataKey: 'gender',
    label: 'Gender',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  balance: {
    dataKey: 'balance',
    label: 'Balance',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  transactionsAvg_viewPoint: {
    dataKey: 'transactionsAvg_viewPoint',
    label: 'Trans Avg Veiw point',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
}
