import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { Input } from 'src/component/atom/input/input'
import { SegmentNameIntl } from 'src/intl/data/result.key'
import iconSearch from 'src/style/icon/icon-search.svg'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { FilterContentType, FilterFieldType, TargetType } from 'src/type/project.type'
import { CategoryFilterValues } from './category-filter-values/category-filter-values'
import styles from './filter-field.scss'
import { NumberFilterValues } from './number-filter-values/number-filter-values'

type PropsType = {
  fields: FilterFieldType[]
  currentField?: FilterContentType | undefined
  selectedFields?: (keyof TargetType)[] | undefined
  onChange?: (filterContent: FilterContentType) => void
}

export const FilterField = ({ fields, currentField, selectedFields, onChange }: PropsType) => {
  const [filterFieldString, setFilterFieldString] = useState<string>('')
  const [selectedFieldName, setSelectedFieldName] = useState(currentField?.name)

  const filteredDatasetFields = useMemo(() => {
    const lowerCaseFilterString = filterFieldString.toLowerCase()

    return fields.filter((field) => field.name.toLowerCase().includes(lowerCaseFilterString))
  }, [fields, filterFieldString])

  const selectedField = useMemo(() => {
    return fields.find((field) => field.name === selectedFieldName)
  }, [selectedFieldName])

  const disabledFields = useMemo(() => {
    return selectedFields?.filter((fieldName) => fieldName !== currentField?.name)
  }, [selectedFields])

  function handleClickItem(fieldName: string) {
    !disabledFields?.includes(fieldName as keyof TargetType) && setSelectedFieldName(fieldName as keyof TargetType)
  }

  function handleChange(values: (string | number[])[]) {
    selectedField &&
      onChange &&
      onChange({
        name: selectedField.name,
        type: selectedField.type,
        values,
      } as FilterContentType)
  }

  return (
    <div className={styles.root}>
      <div className={styles.fields}>
        <div className={styles.filter}>
          <div className={styles.inputWrapper}>
            <InlineSVG className={styles.icon} src={iconSearch} />
            <Input
              type='search'
              placeholder='Search'
              sizeType='sm'
              isFullWidth
              borderType='none'
              onChange={(e) => setFilterFieldString(e.currentTarget.value)}
            />
          </div>
        </div>
        <ul className={styles.fieldList}>
          {filteredDatasetFields.map((field) => (
            <li
              key={field.name}
              className={classNames(
                styles.fieldItem,
                { [styles.active]: selectedFieldName === field.name },
                { [styles.disabled]: disabledFields?.includes(field.name) },
              )}
              onClick={() => handleClickItem(field.name)}
            >
              <div className={styles.fieldName}>{SegmentNameIntl[field.name] || field.name}</div>
            </li>
          ))}
        </ul>
      </div>
      {selectedField?.type === FieldTypesEnum.CATEGORY && (
        <CategoryFilterValues
          key={selectedField.name}
          categories={selectedField?.option}
          selectedCategories={
            currentField &&
            currentField.values &&
            currentField.type === FieldTypesEnum.CATEGORY &&
            currentField.name === selectedField.name
              ? currentField.values
              : undefined
          }
          isCurrentField={!!currentField}
          onChange={handleChange}
        />
      )}
      {selectedField?.type === FieldTypesEnum.NUMBER && (
        <NumberFilterValues
          key={selectedField.name}
          min={selectedField.option[0]}
          max={selectedField.option[1]}
          defaultValue={
            currentField &&
            currentField.values &&
            currentField.type === FieldTypesEnum.NUMBER &&
            currentField.name === selectedField.name
              ? currentField.values[0]
              : undefined
          }
          isCurrentField={!!currentField}
          onChange={handleChange}
        />
      )}
    </div>
  )
}
