import classNames from 'classnames'
import React, { useMemo } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { SymmetryButton } from 'src/component/atom/symmetry-button/symmetry-button'
import { useVisibleWithOutsideClick } from 'src/hook/use-visible-with-outside-click'
import { SegmentNameIntl } from 'src/intl/data/result.key'
import IconPlus from 'src/style/icon/icon-plus.svg'
import IconX from 'src/style/icon/icon-x.svg'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { FilterContentType, FilterFieldType, TargetType } from 'src/type/project.type'
import { FilterField } from './filter-field/filter-field'
import styles from './filter-item.scss'

type PropsType = {
  filterContent?: FilterContentType | undefined
  fields?: FilterFieldType[]
  selectedFields?: (keyof TargetType)[] | undefined
  onChange?(filterContent: FilterContentType | undefined): void
}

export const FilterItem = ({ filterContent, fields = [], selectedFields, onChange }: PropsType) => {
  const { ref, isVisible, setIsVisible } = useVisibleWithOutsideClick<HTMLDivElement>(false)

  const content = useMemo(() => {
    if (filterContent) {
      if (filterContent.type === FieldTypesEnum.CATEGORY) {
        return filterContent.values?.join(', ')
      } else if (filterContent.type === FieldTypesEnum.NUMBER) {
        return filterContent.values?.map((range) => range.join('~')).join(', ')
      } else {
        return
      }
    }
  }, [filterContent?.values])

  function handleClickSelf() {
    setIsVisible(!isVisible)
  }

  function handleClickDeleteSelf() {
    onChange && onChange(undefined)
    setIsVisible(false)
  }

  function handleChange(filterContent: FilterContentType) {
    onChange && onChange(filterContent)
    setIsVisible(false)
  }

  return (
    <div ref={ref} className={styles.root}>
      {filterContent ? (
        <div className={classNames(styles.item)} onClick={handleClickSelf}>
          <span className={styles.fieldName}>{SegmentNameIntl[filterContent.name] || filterContent.name}</span>
          <span className={styles.values}>{content || `設定なし`}</span>
          <SymmetryButton sizeType='xs' isRounded noShape onClick={handleClickDeleteSelf}>
            <InlineSVG className={styles.deleteIcon} src={IconX} />
          </SymmetryButton>
        </div>
      ) : (
        <SymmetryButton
          sizeType='md'
          buttonColor={isVisible ? 'tertiary' : 'black'}
          isRounded
          outlined
          onClick={handleClickSelf}
        >
          <InlineSVG className={styles.addIcon} src={IconPlus} />
        </SymmetryButton>
      )}
      {isVisible && (
        <FilterField
          fields={fields}
          currentField={isVisible && filterContent}
          onChange={handleChange}
          selectedFields={selectedFields}
        />
      )}
    </div>
  )
}
